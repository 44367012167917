<template>
  <!--
    Displays a list of themes used in the videoPostprocessing.
  -->
  <div class="themeList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(themes)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(themes)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showEditTheme(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>Rename</span>
              </button>
              <router-link
                :to="`/videopostprocessing/theme/${ props.dataItem.id }`"
                class="btn btn-primary btn-sm"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="eye"
                />
                <span>Elements</span>
              </router-link>
              <button
                class="btn btn-secondary btn-sm"
                @click="cloneTheme(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="clone"
                />
                <span>Clone</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmRemoveModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>

      <Sidebar
        v-if="showSidebarBoolean"
        :show-sidebar="showSidebarBoolean"
        @close="hideSidebar"
      >
        <ThemeAdd
          v-if="addingElement"
          @reloadThemes="reloadThemeAndCloseSidebar()"
        />
        <ThemeEdit
          v-else
          :theme-id="currentId"
          @reloadThemes="reloadThemeAndCloseSidebar()"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('themeListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeTheme()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';
import ThemeEdit from '@/components/VideoPostprocessing/Theme/ThemeEdit.vue';
import ThemeAdd from '@/components/VideoPostprocessing/Theme/ThemeAdd.vue';

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "ThemeList",
  components: {
    Sidebar,
    ThemeEdit,
    ThemeAdd,
    SweetModal
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      introOutros: null,
      themes: null,
      showSidebarBoolean: false,
      currentId: null,
      addingElement: false,
      indexToDelete: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '380px'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t("themes")
    }
  },
  created () {
    this.getThemes();
  },
  methods: {
    //#region Sidebar
      showSidebar () {
        this.showSidebarBoolean = true;
      },
      hideSidebar () {
        this.showSidebarBoolean = false;
        this.currentComponent = null;
      },
      showAddTheme () {
        this.addingElement = true;
        this.showSidebar();
      },
      showEditTheme (emitValues) {
        this.addingElement = false;
        this.currentId = emitValues;
        this.showSidebar();
      },
    //#endregion

    //#region API-calls
      reloadThemeAndCloseSidebar () {
        this.hideSidebar();
        this.getThemes();
        this.$emit("reloadAuditLogs");
      },
      openConfirmRemoveModal (index) {
        this.indexToDelete = index;
        this.$refs.confirmDelete.open();
      },
      removeTheme () {
        this.axios.delete(`/VideoPostProcessing/DeleteTheme?themeId=${ this.indexToDelete }`)
          .then(() => {
            this.getThemes();
            this.$emit("reloadAuditLogs");
          })
          .finally(() => {
            this.indexToDelete = null;
            this.$refs.confirmDelete.close();
          });
      },
      addIntroOutro () {
        this.loading = true;
        this.axios.put('/VideoPostProcessing/AddIntroOutro')
          .then(() => {
            this.getIntroOutros();
          });
      },
      getThemes () {
        this.axios.get('/VideoPostProcessing/GetThemes')
          .then((response) => {
            if (response == null) {
              return;
            }
            if (response.data == null) {
              return;
            }
            this.themes  = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      cloneTheme (themeId) {
        this.axios.put(`/VideoPostProcessing/CloneTheme?themeId=${themeId }`)
          .then((response) => {
            if (response == null) {
              return;
            }
            if (response.status == 200) {
              this.$snotify.success(this.$t('themeListComp.cloneSuccess'));
              this.$emit("reloadAuditLogs");
              this.getThemes();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    //#endregion
  }
}
</script>
<style>
.themeList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>

<template>
  <!--
    Used to edit the themes that are used in the VideoPostprocessing.
  -->
  <div class="themeEdit">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="theme">
        <h4 class="m-0 mt-3">
          {{ $t('theme') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('name') }}</label>
        <input
          v-model="theme.name"
          v-focus
          :class="['form-control', { 'is-invalid': errors.has('Name') }]"
          type="text"
          placeholder="please enter the themes name"
        >
        <span
          v-show="errors.has('Name')"
          class="badge badge-danger"
        >{{ errors.first('Name') }}</span>
        <button
          class="btn btn-sm btn-primary mt-4 float-right"
          @click="updateTheme"
        >
          <font-awesome-icon
            class="mr-2"
            icon="upload"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
export default {
  name: "ThemeEdit",
  mixins: [
    errorMixin
  ],
  props: {
    themeId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      theme: null,
      loading: true
    }
  },
  watch: {
    themeId: function () {
      this.getTheme();
    }
  },
  created () {
    this.getTheme();
  },
  methods: {
    getTheme () {
      this.loading = true;
      this.axios.get(`/VideoPostProcessing/GetTheme?themeId=${ this.themeId }`)
        .then((response) => {
          this.theme = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateTheme () {
      let themeDto = {
        'Name': this.theme.name,
        'Id': parseInt(this.theme.id),
      };
      this.axios.put(`/VideoPostProcessing/UpdateTheme`, themeDto)
        .then(() => {
          this.$emit("reloadThemes");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<template>
  <!--
    Used to provide data in order to add new themes to be used for the VideoPostprocessing.
  -->
  <div class="themeAdd">
    <h4 class="m-0 mt-3">
      {{ $t('theme') }}
    </h4>
    <hr class="m-0 mb-3">
    <label>{{ $t('name') }}</label>
    <input
      v-model="themeName"
      v-focus
      :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
      :placeholder="$t('themeAddComp.pleaseEnterName')"
      type="text"
    >
    <span
      v-show="errors.has('Duration')" 
      class="badge badge-danger"
    >{{ errors.first('Name') }}</span>
    <button
      class="btn btn-primary mt-4 float-right"
      @click="addTheme"
    >
      <font-awesome-icon
        class="mr-2"
        icon="upload"
      />{{ $t('save') }}
    </button>
    <div class="clearfix" />
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "ThemeAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      themeName: null,
      loading: true
    }
  },
  methods: {
    addTheme () {
      let theme = {
        'Name': this.themeName
      }
      this.axios.put('/VideoPostProcessing/AddTheme', theme)
        .then(() => {
          this.$emit("reloadThemes");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
